

console.log('Hello World!')
var $slider = $("#image-slider");
var $prevArrow = $('.prev');
var $nextArrow = $('.next');

var slider = $slider.slick({
  infinite: false,
  slidesToShow: 2,
  slidesToScroll:2,
  cssEase:'ease-in-out ',
  speed:800,
  prevArrow: $prevArrow,
  nextArrow: $nextArrow,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        slidesToScroll:1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll:1,
        dots: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

$('#mobile-nav-btn').click(function () {
  $(this).toggleClass('is-active');
  $('#mobile-nav').toggleClass('hidden');
});


$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll > 0) {
    $('#site-nav').addClass('bg-blue transition-all duration-500 ease-in-out');
  } else {
    $('#site-nav').removeClass('bg-blue');
  }
});

var carouselForwardBtn = document.getElementById('carousel-forward');
var carouselBackBtn = document.getElementById('carousel-backward');
var customCarousel = document.getElementById('custom-carousel')

carouselForwardBtn.addEventListener(
  'click',
  function () {
      customCarousel.scrollBy({
        left: +300,
        behavior: 'smooth',
      });
  },
  false
);

carouselBackBtn.addEventListener(
  'click',
  function () {
      customCarousel.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
  },
  false
);

//untapped-slides
var $untapped = $("#untapped-slides");


var untapped = $untapped.slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll:1,
    // prevArrow: $prevArrow,
    // nextArrow: $nextArrow,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });


//optimize verion below

var $body = $(document.body);
var scrollPosition = window.pageYOffset;

//Disable scroll
var oldWidth = $body.innerWidth();
$body.addClass('no-scroll');
$body.css('top', `-${scrollPosition}px`);
$body.width(oldWidth);




var preloaderAnimation = document.getElementById('preloader');
var preloader = document.getElementById('lottie-animation');

var preloaderAnimationMob = document.getElementById('preloader-mob');
var preloaderMob = document.getElementById('lottie-animation-mob');

const video = document.getElementById('hero-video');


    function completeAnimation(animation, element, delay) {
      console.log('lottie complete');
      //console.log(animation.currentFrame);
      if ($body.css('overflow') !== 'hidden') { scrollPosition = window.pageYOffset; }
      $body.removeClass('no-scroll').css({
        overflow: '',
        position: '',
        top: '',
        width: ''
      });
      $(window).scrollTop(scrollPosition);

      element.classList.add('opacity-0'); //enable this later

      AOS.init({
        duration: 800,
        easing: 'ease-out',
      });

      setTimeout(function() {
        element.remove(); //enable this later
        //video.play()
      }, delay); // Adjust the delay if needed
    }

    preloaderAnimation.addEventListener('complete', function() {
      console.log('lottie complete desktop');
      completeAnimation(preloaderAnimation, preloader, 100);
    });

    preloaderAnimationMob.addEventListener('complete', function() {
      completeAnimation(preloaderAnimationMob, preloaderMob, 100);
    });





function checkVideoPlay(event, animation, video, targetProgress) {
  const totalFrames = animation.getLottie().totalFrames;
  const targetFrame = Math.round(targetProgress * totalFrames);

  if (video && event.detail.frame >= targetFrame) {
    video.play();
    animation.removeEventListener('frame', checkVideoPlay);
  }
}

preloaderAnimation.addEventListener('frame', function (event) {
  checkVideoPlay(event, preloaderAnimation, video, 0.6);
});

preloaderAnimationMob.addEventListener('frame', function (event) {
  checkVideoPlay(event, preloaderAnimationMob, video, 0.6);
});

$(document).ready(function() {
  $('#signup-as').select2({
    minimumResultsForSearch: Infinity,

  });
});